module.exports = {
	pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
	title: 'Be The U In Unique', // Navigation and Site Title
	titleAlt: 'Be The U In Unique', // Title for JSONLD
	description: 'Coaching',
	headline: 'Coaching', // Headline for schema.org JSONLD
	url: 'https://betheuinunique.co.uk', // Domain of your site. No trailing slash!
	siteLanguage: 'en', // Language Tag on <html> element
	// logo: '/src/images/svg/betheuinunique-logo.svg', // Used for SEO
	ogLanguage: 'en_GB', // Facebook Language

	// JSONLD / Manifest
	// favicon: '/src/images/far-beyond-favicon.png', // Used for manifest favicon generation
	shortName: 'Be The U In Unique', // shortname for manifest. MUST be shorter than 12 characters
	author: 'Jack Kemmish', // Author for schemaORGJSONLD
	themeColor: '#56a03f',
	backgroundColor: '#56a03f',

	twitter: '', // Twitter Username
	facebook: '', // Facebook Site Name
	googleAnalyticsID: 'G-D8E7GM8MNZ',
}
