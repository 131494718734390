import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'
import htmlSerializer from '../../utils/htmlSerializer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const GeneralContent = ({ slice }) => {
	const revealRefs = useRef([])
	revealRefs.current = []

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
		}

		revealRefs.current.forEach((el, index) => {
			const children = el.children[0].children
			const newArray = [...children]
			newArray.forEach((elem) => {
				gsap.fromTo(
					elem,
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						ease: 'power4.out',
						stagger: 0.2,
						duration: 1.5,
						scrollTrigger: {
							id: `general-content-${index + 1}`,
							trigger: elem,
							start: 'top 90%',
							end: 'top top',
							once: true,
						},
					}
				)
			})
		})
	}, [])

	const theme = slice.primary.theme_colour
		? slice.primary.theme_colour
		: 'white'
	const moduleClasses = `module -theme-${theme}`
	return (
		<section className={moduleClasses}>
			<div ref={addToRefs}>
				<Wrapper className='general-content typ'>
					<RichText
						render={slice.primary.content.raw || []}
						htmlSerializer={htmlSerializer}
					/>
				</Wrapper>
			</div>
		</section>
	)
}

export default GeneralContent
