import React from 'react'
import { RichText } from 'prismic-reactjs'
import Wrapper from '../Wrap'

import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Pagination, Autoplay])
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

const Testimonial = ({ slice }) => {
	if (!slice) return null

	const theme = slice.primary.theme_colour
		? slice.primary.theme_colour
		: 'white'
	const moduleClasses = `module -theme-${theme}`
	const title = slice.primary.title.raw
	const testimonials = slice.items
	const testimonialSlides = testimonials
		? testimonials.map((item, index) => (
				<SwiperSlide key={`slide-${index}`}>
					<div className='testimonial__item'>
						<div className='testimonial__content'>
							<RichText render={item.testimonial.raw || []} />
						</div>
						<div className='testimonial__details'>
							<RichText render={item.person_name.raw || []} />
							<RichText render={item.cite.raw || []} />
						</div>
					</div>
				</SwiperSlide>
		  ))
		: null
	const paginationRef = React.useRef(null)

	return (
		<>
			<section className={moduleClasses}>
				<div>
					<Wrapper className='testimonial typ'>
						<div className='testimonial__carousel'>
							<RichText render={title || []} />
							<Swiper
								modules={[Pagination]}
								spaceBetween={50}
								slidesPerView={1}
								loop={false}
								watchOverflow={true}
								pagination={{
									el: paginationRef.current,
									type: 'bullets',
									clickable: true,
								}}
								onSwiper={(swiper) => console.log(swiper)}>
								{testimonialSlides}
								<div ref={paginationRef}></div>
							</Swiper>
						</div>
					</Wrapper>
				</div>
			</section>
		</>
	)
}

export default Testimonial
