import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'
import htmlSerializer from '../../utils/htmlSerializer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const InfoWithImage = ({ slice }) => {
	const revealRefs = useRef([])
	revealRefs.current = []

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
		}

		revealRefs.current.forEach((el, index) => {
			const children = el.children
			gsap.fromTo(
				children,
				{
					autoAlpha: 0,
					y: 60,
				},
				{
					autoAlpha: 1,
					y: 0,
					ease: 'power4.out',
					stagger: 0.2,
					duration: 1.5,
					scrollTrigger: {
						id: `general-content-${index + 1}`,
						trigger: children,
						start: 'top 90%',
						end: 'top top',
						once: true,
					},
				}
			)
		})
	}, [])

	const featuredImage = slice.primary.featured_image
	const textPosition = slice.primary.text_position
		? `info-with-image__container typ ${slice.primary.text_position}`
		: 'info-with-image__container typ left'

	return (
		<section className='module'>
			<Wrapper className='info-with-image -contained'>
				<div className={textPosition}>
					<div className='featured-image'>
						<img
							className='lazyload'
							data-src={featuredImage ? featuredImage.fixed.src : ''}
							alt={featuredImage ? featuredImage.alt : ''}
						/>
					</div>
					<div className='text-content' ref={addToRefs}>
						<RichText
							render={slice.primary.text.raw || []}
							htmlSerializer={htmlSerializer}
						/>
					</div>
				</div>
			</Wrapper>
		</section>
	)
}

export default InfoWithImage
