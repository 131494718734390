import React from 'react'
import {
	InfoWithImage,
	TextInfo,
	GeneralContent,
	Blockquote,
	HeroImageWithText,
	KeyTextGrid,
	Testimonial,
	PricingTable,
} from './slices'

const SliceZone = ({ slices }) => {
	const sliceComponents = {
		info_with_image: InfoWithImage,
		text_info: TextInfo,
		general_content: GeneralContent,
		blockquote: Blockquote,
		hero_image_with_text: HeroImageWithText,
		key_text_grid: KeyTextGrid,
		testimonial: Testimonial,
		pricing_table: PricingTable,
	}

	return slices.map((slice, index) => {
		const SliceComponent = sliceComponents[slice.slice_type]
		if (SliceComponent) {
			return <SliceComponent slice={slice} key={`slice-${index}`} />
		}
		return null
	})
}

export default SliceZone
