import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const Blockquote = ({ slice }) => {
	const revealRefs = useRef([])
	revealRefs.current = []

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
		}

		revealRefs.current.forEach((el, index) => {
			const children = el.children[0]
			gsap.fromTo(
				children,
				{
					autoAlpha: 0,
					y: 60,
				},
				{
					autoAlpha: 1,
					y: 0,
					ease: 'power4.out',
					duration: 1.5,
					scrollTrigger: {
						id: `blockquote-${index + 1}`,
						trigger: el,
						start: 'top 90%',
						end: 'top top',
						once: true,
					},
				}
			)
		})
	}, [])

	const moduleClasses = `module blockquote`
	const quote = slice.primary.text.raw[0].text
	const backgroundImage = slice.primary.image
	return (
		<section className={moduleClasses}>
			<div>
				<Wrapper className='blockquote typ'>
					<div className='blockquote__wrapper' ref={addToRefs}>
						<h2 className='blockquote'>{quote}</h2>
						{slice.primary.cite.raw[0] ? (
							<h6 className='cite'>— {slice.primary.cite.raw[0].text}</h6>
						) : (
							''
						)}
					</div>
					{backgroundImage.url ? (
						<div className='blockquote__image'>
							<img
								className='lazyload'
								data-src={backgroundImage ? backgroundImage.url : ''}
								alt={backgroundImage ? backgroundImage.alt : ''}
							/>
						</div>
					) : null}
				</Wrapper>
			</div>
		</section>
	)
}

export default Blockquote
