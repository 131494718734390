import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'
import htmlSerializer from '../../utils/htmlSerializer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const HeroImageWithText = ({ slice }) => {
	const revealRefs = useRef([])
	revealRefs.current = []

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
		}

		revealRefs.current.forEach((el, index) => {
			const childrenOne = el.children[0]
			const childrenTwo = el.children[1]?.children
			const childrenThree = el.children[2]?.children
			if (childrenOne) {
				gsap.fromTo(
					childrenOne,
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						ease: 'power4.out',
						stagger: 0.2,
						duration: 1.5,
						scrollTrigger: {
							id: `general-content-${index + 1}`,
							trigger: childrenOne,
							start: 'top 90%',
							end: 'top top',
							once: true,
						},
					}
				)
			}

			if (childrenTwo) {
				gsap.fromTo(
					childrenTwo,
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						ease: 'power4.out',
						stagger: 0.2,
						duration: 1.5,
						scrollTrigger: {
							id: `general-content-${index + 1}`,
							trigger: childrenTwo,
							start: 'top 90%',
							end: 'top top',
							once: true,
						},
					}
				)
			}

			if (childrenThree) {
				gsap.fromTo(
					childrenThree,
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						ease: 'power4.out',
						stagger: 0.2,
						duration: 1.5,
						scrollTrigger: {
							id: `general-content-${index + 1}`,
							trigger: childrenThree,
							start: 'top 90%',
							end: 'top top',
							once: true,
						},
					}
				)
			}
		})
	}, [])

	const heroImage = slice.primary.image
	return (
		<section className='module hero-with-text'>
			<div className='hero-image-content__container typ'>
				<div className='hero-image-content__image'>
					<img
						className='lazyload'
						data-src={heroImage ? heroImage.url : ''}
						alt={heroImage ? heroImage.alt : ''}
					/>
				</div>
				<div className='hero-image-content__content' ref={addToRefs}>
					<div className='hero-image-content__content-column'>
						<RichText
							render={slice.primary.content.raw || []}
							// serializeHyperlink={GatsbyLink}
							htmlSerializer={htmlSerializer}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}

export default HeroImageWithText
