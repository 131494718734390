import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'
import htmlSerializer from '../../utils/htmlSerializer'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const TextInfo = ({ slice }) => {
	const revealRefs = useRef([])
	revealRefs.current = []

	const addToRefs = (el) => {
		if (el && !revealRefs.current.includes(el)) {
			revealRefs.current.push(el)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			gsap.registerPlugin(ScrollTrigger)
		}

		revealRefs.current.forEach((el, index) => {
			const children = el.children
			const newArray = [...children]
			newArray.forEach((elem) => {
				gsap.fromTo(
					elem,
					{
						autoAlpha: 0,
						y: 60,
					},
					{
						autoAlpha: 1,
						y: 0,
						ease: 'power4.out',
						stagger: 0.2,
						duration: 1.5,
						scrollTrigger: {
							id: `general-content-${index + 1}`,
							trigger: elem,
							start: 'top 90%',
							end: 'top top',
							once: true,
						},
					}
				)
			})
		})
	}, [])

	return (
		<section className='module'>
			<Wrapper className='text-info -contained'>
				<div className='text-info__title' ref={addToRefs}>
					<RichText render={slice.primary.title.raw || []} />
				</div>
				<div className='text-info__container typ'>
					<div className='left-column' ref={addToRefs}>
						<RichText
							render={slice.primary.left_column_text.raw || []}
							htmlSerializer={htmlSerializer}
						/>
					</div>
					<div className='right-column' ref={addToRefs}>
						<RichText
							render={slice.primary.right_column_text.raw || []}
							htmlSerializer={htmlSerializer}
						/>
					</div>
				</div>
			</Wrapper>
		</section>
	)
}

export default TextInfo
