import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const KeyTextGrid = ({ slice }) => {
	// const revealRefs = useRef([])
	// revealRefs.current = []

	// const addToRefs = (el) => {
	//   if (el && !revealRefs.current.includes(el)) {
	//     revealRefs.current.push(el)
	//   }
	// }

	// useEffect(() => {
	//   if (typeof window !== 'undefined') {
	//     gsap.registerPlugin(ScrollTrigger)
	//   }

	//   revealRefs.current.forEach((el, index) => {
	//     const children = el.children[0].children
	//     gsap.fromTo(
	//       children,
	//       {
	//         autoAlpha: 0,
	//         y: 60,
	//       },
	//       {
	//         autoAlpha: 1,
	//         y: 0,
	//         ease: 'power4.out',
	//         stagger: 0.2,
	//         duration: 1.5,
	//         scrollTrigger: {
	//           id: `general-content-${index + 1}`,
	//           trigger: children,
	//           start: 'top 80%',
	//           end: 'top top',
	//           once: true,
	//         },
	//       }
	//     )
	//   })
	// }, [])

	const items = slice.items.map((item, index) => (
		<div className='key-text-grid__item'>
			<div className='key-text-grid__item-inner'>
				<RichText render={item.content.raw || []} />
			</div>
		</div>
	))

	return (
		<section className='module'>
			<div>
				{/* ref={addToRefs} */}
				<Wrapper className='key-text-grid__wrapper typ'>
					<div className='key-text-grid'>{items}</div>
				</Wrapper>
			</div>
		</section>
	)
}

export default KeyTextGrid
