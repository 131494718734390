import React from 'react'
import { RichText } from 'prismic-reactjs'
import Wrapper from '../Wrap'

const PricingTable = ({ slice }) => {
	if (!slice) return null

	const theme = slice.primary.theme_colour
		? slice.primary.theme_colour
		: 'white'
	const moduleClasses = `module -theme-${theme}`
	const title = slice.primary.title.raw
	const prices = slice.items
	const pricesItem = prices
		? prices.map((item, index) => (
				<div className='price__item' key={`price-${index}`}>
					<div className='price__item-quote'>
						{item.pricing_image ? (
							<div className='price__item-quote__image'>
								<img
									className='lazyload'
									data-src={
										item.pricing_image ? item.pricing_image.fixed.src : ''
									}
									alt={item.pricing_image ? item.pricing_image.alt : ''}
								/>
								{item.price_quote.text ? (
									<h5 className='price__item-quote__title'>
										"{item.price_quote.text}"
									</h5>
								) : (
									''
								)}
							</div>
						) : (
							''
						)}
					</div>
					<div className='price__item-content typ'>
						{item.price_title.raw ? (
							<div className='price__item-content__title'>
								<RichText render={item.price_title.raw || []} />
							</div>
						) : (
							''
						)}
						{item.price_description.raw ? (
							<div className='price__item-content__description'>
								<RichText render={item.price_description.raw || []} />
							</div>
						) : (
							''
						)}
						{item.price_included_features.raw ? (
							<div className='price__item-content__included-features'>
								<h5>Included features:</h5>
								<RichText render={item.price_included_features.raw || []} />
							</div>
						) : (
							''
						)}
						{item.price.text ? (
							<div className='price__item-content__price'>
								<h3>Price: £{item.price.text || []}</h3>
							</div>
						) : (
							''
						)}
						{item.link.url ? (
							<div className='price__item-content__button button'>
								<a
									className='price__item-content__button-btn'
									href={item.link.url}
									target={item.link.target}>
									Enquire
								</a>
							</div>
						) : (
							''
						)}
					</div>
				</div>
		  ))
		: null

	return (
		<section className={moduleClasses}>
			<Wrapper>
				<div className='price__title'>
					<RichText render={title || []} />
				</div>
				<div className='price__container'>{pricesItem}</div>
			</Wrapper>
		</section>
	)
}

export default PricingTable
