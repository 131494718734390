import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 0 20px;
  }

  &.-full {
    max-width: 100%;
  }

  &.full-width-image {
    padding-left: 0;
    padding-right: 0;
  }

  .logo-list__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const Wrap = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

export default Wrap
